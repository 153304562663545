import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, QueryParameter} from 'two-core';
import {localStorageAttributes} from '../config/localStorageAttributes';

export class SharedLocationsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    this.endpoint = config(companyId).endpoints.sharedLocations ?? '';
  }

  async getSharedLocations(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(`${this.endpoint}`, params)
      .then(data => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}
