import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import OrdersService from '../../services/OrdersService';
import {NavLink} from 'react-router-dom';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {
  MessageService,
  TwoDataTable,
  AppColumnMenuBodyTemplate,
  ToastService,
  AppMenuItem,
  AppMenuItemTemplate,
  TwoMessage,
  OrdersPrinterComponent,
  UsersService,
  getTwoDateFormat,
  DrawingsDialog,
  TempFile,
} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {MultiSelect} from 'primereact/multiselect';
import {
  QueryParameter,
  DropdownOption,
  ApiListResponse,
  PaOrder,
  LocationReference,
  OrderStage,
  PaCompany,
  UserReference,
  PaContact,
  OrderPatch,
  PaProductDefinition,
  AppointmentStage,
  OrderAggregate,
  JobStage,
  Appointment,
  PaOrderPatch,
  MapOf,
  DrawingFileReference,
} from 'two-core';
import '../../scss/CustomTable.scss';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitchChangeParams} from 'primereact/inputswitch';
import './OrderListComponent.scss';
import {messages} from '../../config/messages';
import {Tooltip} from 'primereact/tooltip';
import {MenuItemOptions} from 'primereact/menuitem';
import SubmitOrderConfirmDialog from '../Order/SubmitOrderConfirmDialog';
import {jobStages, orderStages} from '../../config/values';
import {RequestJobDialog} from '../Job/RequestJobDialog/RequestJobDialog';
import CompaniesService from '../../services/CompaniesService';
import AddEditOrderDialog from '../Order/AddEditOrderDialog';
import ContactsService from '../../services/ContactsService';
import {
  faPrint,
  faTape,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTrashUndo,
  faFolderOpen,
} from '@fortawesome/pro-regular-svg-icons';
import {localStorageAttributes} from '../../config/localStorageAttributes';
import ProductsService from '../../services/ProductsService';
import {JobAppointmentReference} from '../Reference/JobAppointmentReference/JobAppointmentReference';
import {Skeleton} from 'primereact/skeleton';
import AppointmentsService from '../../services/AppointmentsService';

export type OrderListMode =
  | 'Estimate'
  | 'Measure'
  | 'New'
  | 'Drawings'
  | 'Credit Check'
  | 'In Production'
  | 'In Shipping'
  | 'Delivered'
  | 'Repairs'
  | 'All';

interface Props {
  mode: OrderListMode;
}

const inactiveJobStages: JobStage[] = ['Completed', 'Completed With Repair', 'Canceled'];

//todo add 'Measure approved' int the array when the state machine is fixed.
const inactiveAppointmentStages: AppointmentStage[] = ['Completed', 'Cancelled'];

interface State {
  newOrder?: PaOrder;
  editOrder?: PaOrder;
  loadingOrders: boolean;
  updatingOrders: boolean;
  loadingCompany: boolean;
  loadingAppointments: boolean;
  items: PaOrder[];
  totalItems: number;
  selectedItems: PaOrder[];
  ordersToPrint: PaOrder[];
  productDefinitions: Map<number, PaProductDefinition[]>;
  appointmentsMap: Map<string, Appointment>; //job_id, job
  activeFilters: {};
  availableOwners: UserReference[];
  filters: {
    order_code: string;
    reference: string;
    size: number | undefined;
    summary: string;
    stage: string;
    job_stage: string;
    owner_ids: string[];
    created_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    submitted_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    approved_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    ecd: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    container_id: string;
    eta: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    delivered_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  error: boolean;
  showAddEditDialog: boolean;
  showSubmitOrderDialog: boolean;
  showDeletedEstimates: boolean;
  showRequestJobDialog: boolean;
  company?: PaCompany;
  printOrders: boolean;
  availableStages: OrderStage[];
  hiddenColumns: Set<string>;
  showDrawingsDialog: boolean;
}

class OrderListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  contactsService: ContactsService | null = null;
  productsService?: ProductsService | null = null;
  toastService: ToastService | null = null;
  companiesService: CompaniesService | null = null;
  appointmentsService?: AppointmentsService;
  usersService?: UsersService;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      totalItems: 0,
      availableOwners: [],
      loadingOrders: false,
      updatingOrders: false,
      loadingCompany: false,
      loadingAppointments: false,
      selectedItems: [],
      ordersToPrint: [],
      productDefinitions: new Map<number, PaProductDefinition[]>(),
      appointmentsMap: new Map<string, Appointment>(),
      activeFilters: {},
      filters: {
        order_code: '',
        size: undefined,
        reference: '',
        stage: '',
        job_stage: '',
        summary: '',
        owner_ids: [],
        created_at: {
          fromDate: null,
          toDate: null,
        },
        submitted_at: {
          fromDate: null,
          toDate: null,
        },
        approved_at: {
          fromDate: null,
          toDate: null,
        },
        ecd: {
          fromDate: null,
          toDate: null,
        },
        container_id: '',
        eta: {
          fromDate: null,
          toDate: null,
        },
        delivered_at: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      error: false,
      showAddEditDialog: false,
      showSubmitOrderDialog: false,
      showDeletedEstimates: false,
      showRequestJobDialog: false,
      printOrders: false,
      availableStages: orderStages,
      hiddenColumns: new Set<string>(),
      showDrawingsDialog: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);

    this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.approvedAtBodyTemplate = this.approvedAtBodyTemplate.bind(this);
    this.ecdBodyTemplate = this.ecdBodyTemplate.bind(this);
    this.etaBodyTemplate = this.etaBodyTemplate.bind(this);
    this.deliveredBodyTemplate = this.deliveredBodyTemplate.bind(this);
    this.jobStageBodyTemplate = this.jobStageBodyTemplate.bind(this);
    this.nextAppointmentBodyTemplate = this.nextAppointmentBodyTemplate.bind(this);
    this.stageFilter = this.stageFilter.bind(this);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterOwnerChange = this.onFilterOwnerChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.createdAtBodyTemplate = this.createdAtBodyTemplate.bind(this);
    this.submittedAtBodyTemplate = this.submittedAtBodyTemplate.bind(this);
    this.submittedAtBodyTemplate = this.submittedAtBodyTemplate.bind(this);
    this.initTableMenu = this.initTableMenu.bind(this);
    this.onHideSubmitConfirmDialog = this.onHideSubmitConfirmDialog.bind(this);
    this.deleteOrders = this.deleteOrders.bind(this);
    this.unDeleteOrders = this.unDeleteOrders.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.loadCompany = this.loadCompany.bind(this);
    this.onShowRequestJobDialog = this.onShowRequestJobDialog.bind(this);
    this.onHideRequestJobDialog = this.onHideRequestJobDialog.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);

    this.printSelectedOrders = this.printSelectedOrders.bind(this);
    this.onPrintDialogOpen = this.onPrintDialogOpen.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {mode} = this.props;
    if (mode !== prevProps.mode) {
      this.modeSetup(mode);
    }
    this.onHideDrawingDialog = this.onHideDrawingDialog.bind(this);
    this.showDrawingDialog = this.showDrawingDialog.bind(this);
    this.onDrawingsSave = this.onDrawingsSave.bind(this);
  }

  async componentDidMount() {
    const {mode} = this.props;
    this.ordersService = this.context.ordersService;
    this.contactsService = this.context.contactsService;
    this.toastService = this.context.toastService;
    this.companiesService = this.context.companiesService;
    this.productsService = this.context.productsService;
    this.appointmentsService = this.context.appointmentsService;
    this.usersService = this.context.usersService;

    this.modeSetup(mode);

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.orderCreated || message === messages.orderUpdated) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name === messages.topSelectionChanged) {
          this.loadData();
        }
      }
    });
  }

  /** Sets up the available stages and column visibilities, starting with all cols visible and all
   * stages relevant.
   */
  modeSetup(mode: OrderListMode) {
    const doesFitting = localStorage.getItem(localStorageAttributes.fittingTypes) !== '';
    let availableStages = [...orderStages];
    const hiddenColumns = new Set<string>();

    switch (mode) {
      case 'All':
      case 'Repairs':
        if (!doesFitting) {
          const index = availableStages.indexOf('Measure', 0);
          if (index > -1) {
            availableStages.splice(index, 1);
          }
        }
        break;
      case 'Estimate':
        availableStages = ['Estimate', 'Repair Estimate'];
        hiddenColumns.add('submitted_at');
        hiddenColumns.add('approved_at');
        hiddenColumns.add('ecd');
        hiddenColumns.add('container_id');
        hiddenColumns.add('eta');
        hiddenColumns.add('delivered_at');
        hiddenColumns.add('job_stage');
        hiddenColumns.add('job_appointment');
        break;
      case 'Measure':
        availableStages = ['Measure'];
        hiddenColumns.add('submitted_at');
        hiddenColumns.add('approved_at');
        hiddenColumns.add('ecd');
        hiddenColumns.add('container_id');
        hiddenColumns.add('eta');
        hiddenColumns.add('delivered_at');
        break;
      case 'New':
        availableStages = ['New'];
        hiddenColumns.add('created_at');
        hiddenColumns.add('approved_at');
        hiddenColumns.add('ecd');
        hiddenColumns.add('container_id');
        hiddenColumns.add('eta');
        hiddenColumns.add('delivered_at');
        hiddenColumns.add('job_stage');
        hiddenColumns.add('job_appointment');
        break;
      case 'Drawings':
        availableStages = [
          'Drawing(s) Required',
          'Drawing Notes Awaiting Approval',
          'Drawing Notes Awaiting Approval Re-Draw',
          'Drawing(s) Awaiting Approval',
          'Re-Draw',
          'Drawing(s) Approved',
        ];
        hiddenColumns.add('created_at');
        hiddenColumns.add('approved_at');
        hiddenColumns.add('ecd');
        hiddenColumns.add('container_id');
        hiddenColumns.add('eta');
        hiddenColumns.add('delivered_at');
        hiddenColumns.add('job_stage');
        hiddenColumns.add('job_appointment');
        break;
      case 'Credit Check':
        availableStages = ['Credit Check'];
        hiddenColumns.add('created_at');
        hiddenColumns.add('ecd');
        hiddenColumns.add('container_id');
        hiddenColumns.add('eta');
        hiddenColumns.add('delivered_at');
        hiddenColumns.add('job_stage');
        hiddenColumns.add('job_appointment');
        break;
      case 'In Production':
        availableStages = ['New', 'Scheduled For Production', 'In Production', 'Production Complete'];
        hiddenColumns.add('created_at');
        hiddenColumns.add('container_id');
        hiddenColumns.add('eta');
        hiddenColumns.add('delivered_at');
        hiddenColumns.add('job_stage');
        hiddenColumns.add('job_appointment');
        break;
      case 'In Shipping':
        availableStages = ['In Shipping'];
        hiddenColumns.add('created_at');
        hiddenColumns.add('delivered_at');
        hiddenColumns.add('job_stage');
        hiddenColumns.add('job_appointment');
        break;
      case 'Delivered':
        availableStages = ['Delivered'];
        hiddenColumns.add('created_at');
        hiddenColumns.add('delivered_at');
        hiddenColumns.add('job_stage');
        hiddenColumns.add('job_appointment');
        break;
    }
    this.setState(
      () => ({
        availableStages: availableStages,
        hiddenColumns: hiddenColumns,
      }),
      () => this.loadData()
    );
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  handleError(error: string): void {
    console.log(error);
    if (!this.state.error) {
      this.toastService?.showError(this.toast, error);

      this.setState({error: true});
    }
  }

  async loadData() {
    const promises: Promise<void | undefined>[] = [];
    promises.push(this.loadCompany());
    promises.push(this.loadOrders());
    Promise.all(promises).catch(error => {
      console.error('Failed Loading Data:' + error);
      this.toastService?.showError(this.toast, 'Failed loading data, please refresh and try again.');
    });
  }

  async loadOrders() {
    const {mode} = this.props;
    const filters: string[] = [];
    this.setState({loadingOrders: true});

    if (this.state.filters.order_code) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.order_code,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference',
          value: this.state.filters.reference,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.size) {
      filters.push(
        JSON.stringify({
          field: 'size',
          value: this.state.filters.size,
        })
      );
    }
    if (this.state.filters.summary) {
      filters.push(
        JSON.stringify({
          field: 'summary',
          value: this.state.filters.summary,
          condition: 'iLike',
        })
      );
    }
    //Stages
    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    } else {
      if (this.state.availableStages && this.state.availableStages.length > 0) {
        filters.push(
          JSON.stringify({
            field: 'stage',
            value: this.state.availableStages,
            condition: 'in',
          })
        );
      }
    }
    if (this.state.filters.owner_ids && this.state.filters.owner_ids.length) {
      filters.push(
        JSON.stringify({
          field: "owner_contact_ref->>'user_id'",
          condition: 'in',
          value: this.state.filters.owner_ids,
        })
      );
    }
    if (this.state.filters.created_at.fromDate) {
      const fromDate = this.state.filters.created_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.created_at.toDate) {
      const toDate = this.state.filters.created_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.submitted_at.fromDate) {
      const fromDate = this.state.filters.submitted_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'submitted_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.submitted_at.toDate) {
      const toDate = this.state.filters.submitted_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'submitted_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.approved_at.fromDate) {
      const fromDate = this.state.filters.approved_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.approved_at.toDate) {
      const toDate = this.state.filters.approved_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.ecd.fromDate) {
      const fromDate = this.state.filters.ecd.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.ecd.toDate) {
      const toDate = this.state.filters.ecd.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.container_id) {
      filters.push(
        JSON.stringify({
          field: 'freight_order.container_id',
          value: this.state.filters.container_id,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.eta.fromDate) {
      const fromDate = this.state.filters.eta.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.eta',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.eta.toDate) {
      const toDate = this.state.filters.eta.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.eta',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.delivered_at.fromDate) {
      const fromDate = this.state.filters.delivered_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.delivered_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.delivered_at.toDate) {
      const toDate = this.state.filters.delivered_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.delivered_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.showDeletedEstimates) {
      filters.push(
        JSON.stringify({
          condition: 'includeDeleted',
          value: true,
        })
      );
    }
    if (this.state.filters.job_stage?.length) {
      filters.push(
        JSON.stringify({
          field: 'job.stage',
          value: this.state.filters.job_stage,
          condition: 'in',
        })
      );
    }
    this.setState({activeFilters: {...filters}});

    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'order_code':
        sortBy.field = 'id';
        break;
      case 'summary':
        sortBy.field = 'size';
        break;
      case 'ecd':
        sortBy.field = 'factory_order.ecd';
        break;
      case 'container_id':
        sortBy.field = 'freight_order.container_id';
        break;
      case 'eta':
        sortBy.field = 'container.eta_to_port';
        break;
      case 'delivered_at':
        sortBy.field = 'freight_order.delivered_at';
        break;
      case 'owner_contact_ref':
        sortBy.field = "owner_contact_ref->>'label'";
        break;
    }

    const sortByStringyField = JSON.stringify(sortBy.field ? sortBy : {field: 'updated_at', direction: 'DESC'});

    const aggregate: OrderAggregate[] = ['jobs'];
    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyField],
      aggregate: aggregate,
    };

    const contactParams: QueryParameter = {
      orderBys: [
        JSON.stringify({field: 'first_name', direction: 'ASC'}),
        JSON.stringify({field: 'last_name', direction: 'ASC'}),
      ],
    };

    this.contactsService
      ?.getContacts(contactParams)
      .then(data => {
        const contacts = (data.records as PaContact[]) ?? [];
        const userRefs = contacts.map(contact => {
          return {
            label: `${contact.first_name} ${contact.last_name}`,
            user_id: contact.user_id,
            contact_id: contact.id,
          } as UserReference;
        });
        this.setState({
          availableOwners: userRefs,
        });
      })
      .catch(error => {
        console.log('Failed to load owners / contact.', error);
      });

    return this.ordersService
      ?.getOrders(params)
      .then((data: ApiListResponse) => {
        const orders = (data.records as PaOrder[]) ?? [];
        this.handleSelectedItems(orders);
        //todo add install mode once available
        if (mode === 'Measure') {
          const appointmentsIdsSet = new Set<string>();
          for (const order of orders) {
            for (const job of order.jobs ?? []) {
              appointmentsIdsSet.add(job.id!);
            }
          }
          this.loadAppointments(appointmentsIdsSet);
        }
        this.setState({
          items: orders,
          totalItems: data.total_records ?? 0,
        });
      })
      .catch(error => {
        this.handleError('Records load failed');
        console.log(error);
      })
      .finally(() => this.setState({loadingOrders: false}));
  }

  async loadCompany() {
    this.setState({loadingCompany: true});
    const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '[]';
    const currentCompany = JSON.parse(currentCompanyString);

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: currentCompany.id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    return this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const company = (data.records as PaCompany[])[0];

        this.setState({
          company: company,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, company load failed, please try again.');
        console.error(error);
      })
      .finally(() => this.setState({loadingCompany: false}));
  }

  async loadAppointments(appointmentsIdsSet: Set<string>) {
    this.setState({loadingAppointments: true});
    const chunkSize = 50;
    const appointmentsIds = Array.from(appointmentsIdsSet);
    const promises = [];
    for (let i = 0; i < appointmentsIds.length; i += chunkSize) {
      const idsChunk = appointmentsIds.slice(i, i + chunkSize);
      const queryFilters: string[] = [];
      queryFilters.push(
        JSON.stringify({
          field: 'job_id',
          value: idsChunk,
          condition: 'in',
        })
      );
      queryFilters.push(
        JSON.stringify({
          field: 'stage',
          value: inactiveAppointmentStages,
          condition: 'notIn',
        })
      );
      const params: QueryParameter = {
        filters: queryFilters,
      };
      promises.push(this.appointmentsService?.getAppointments(params));
    }
    Promise.all(promises)
      .then(listResponses => {
        const appointments = listResponses.map(response => (response?.records ?? []) as Appointment[]).flat();
        const appointmentsMap = new Map<string, Appointment>();
        for (const appointment of appointments) {
          appointmentsMap.set(appointment.job_id!, appointment);
        }
        this.setState({appointmentsMap: appointmentsMap});
      })
      .finally(() => this.setState({loadingAppointments: false}));
  }

  async onPageChange(e: DataTablePageParams) {
    this.setState({pagination: {offset: e.first, pageSize: e.rows}}, () => {
      this.loadOrders();
    });
  }

  async onSort(e: DataTableSortParams) {
    this.setState({sortBy: {field: e.sortField, order: e.sortOrder}}, () => {
      this.loadOrders();
    });
  }

  async onFilterChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | DropdownChangeParams
      | InputSwitchChangeParams
      | DateColumnFilterChangeEvent
  ) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadOrders();
  }

  async onFilterOwnerChange(e: DropdownChangeParams) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          owner_ids: e.value,
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  handleSelectedItems(allItems: PaOrder[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: PaOrder[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: PaOrder[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: PaOrder) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  showDrawingDialog(rowData: PaOrder) {
    this.setState({
      editOrder: rowData,
      showDrawingsDialog: true,
    });
  }

  onHideDrawingDialog() {
    this.setState({showDrawingsDialog: false});
  }

  async onDrawingsSave(
    orderId: string,
    paOrderPatch: PaOrderPatch,
    tempFiles: Map<DrawingFileReference['index'], TempFile>
  ) {
    this.setState({loadingOrders: true});
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    this.ordersService
      ?.updateOrder(companyId, orderId, paOrderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.toast, `Order ${orderId} updated successfully.`);
        this.setState({showDrawingsDialog: false});
      })
      .catch(error => {
        this.handleError(`${orderId} update failed`);
        console.error('error: ' + error);
      })
      .finally(() => {
        MessageService.sendMessage(messages.orderUpdated);
      });
  }

  codeBodyTemplate(rowData: PaOrder) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={() => this.initMenuItems()}
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <NavLink to={'Order/' + rowData.id}>{rowData.id}</NavLink>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  stageBodyTemplate(rowData: PaOrder) {
    return (
      <span
        className={`stage-badge stage-${rowData.stage!.toLowerCase().replaceAll(' ', '-').replaceAll(/[()]/g, '')}`}
      >
        {rowData.stage}
      </span>
    );
  }

  ownerBodyTemplate(rowData: PaOrder) {
    return <span>{rowData.owner_contact_ref?.label ?? ''}</span>;
  }

  approvedAtBodyTemplate(rowData: PaOrder) {
    const dateFormat = this.usersService?.settings?.date_format;
    const formated_approved_on = rowData.approved_at
      ? DateTime.fromISO(rowData.approved_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'date'))
      : '';
    const id = `approved-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>{formated_approved_on}</Tooltip>
        <span id={id}>{formated_approved_on}</span>
      </>
    );
  }
  submittedAtBodyTemplate(rowData: PaOrder) {
    const dateFormat = this.usersService?.settings?.date_format;
    const formated_submitted = rowData.submitted_at
      ? DateTime.fromISO(rowData.submitted_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'date'))
      : '';
    const id = `sumitted-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>
          {formated_submitted} {rowData.submitted_by}
        </Tooltip>
        <span id={id}>
          {formated_submitted} {this.getInitials(rowData.submitted_by)}
        </span>
      </>
    );
  }
  createdAtBodyTemplate(rowData: PaOrder) {
    const dateFormat = this.usersService?.settings?.date_format;
    const formattedDate = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'date'))
      : '';
    const formattedDateTime = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))
      : '';
    const id = `created-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>{formattedDateTime}</Tooltip>
        <span id={id}>{formattedDate}</span>
      </>
    );
  }

  getInitials(fullName: string | undefined): string {
    if (fullName?.length) {
      return fullName
        .split(' ')
        .map(name => name[0])
        .join('');
    }
    return '';
  }

  ecdBodyTemplate(rowData: PaOrder) {
    const dateFormat = this.usersService?.settings?.date_format;
    const formated_ecd = rowData.ecd
      ? DateTime.fromISO(rowData.ecd.toString()).toFormat(getTwoDateFormat(dateFormat, 'date'))
      : '';
    return <span>{formated_ecd}</span>;
  }

  etaBodyTemplate(rowData: PaOrder) {
    const dateFormat = this.usersService?.settings?.date_format;
    const formattedEta = rowData.eta
      ? DateTime.fromISO(rowData.eta.toString()).toFormat(getTwoDateFormat(dateFormat, 'date'))
      : '';
    return <span>{formattedEta}</span>;
  }

  deliveredBodyTemplate(rowData: PaOrder) {
    const dateFormat = this.usersService?.settings?.date_format;
    const formated_delivered_at = rowData.delivered_at
      ? DateTime.fromISO(rowData.delivered_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'date'))
      : '';
    return <span>{formated_delivered_at}</span>;
  }

  deleteBodyTemplate(rowData: PaOrder) {
    return rowData.deleted ? 'Yes' : 'No';
  }

  jobStageBodyTemplate(order: PaOrder) {
    const activeJob = order.jobs?.find(job => !inactiveJobStages.includes(job.stage));
    return this.stageTemplate(activeJob?.stage, activeJob?.fitting_provider_id, activeJob?.id);
  }

  stageTemplate(stage?: JobStage, fittingProviderId?: string, jobId?: string) {
    const currentCompanyId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? '';
    if (stage) {
      let stageClass;
      if (currentCompanyId === fittingProviderId) {
        stageClass = `job-stage-${stage!.toLowerCase().replaceAll(' ', '-').replaceAll(/[()]/g, '')}`;
      } else {
        stageClass = 'job-stage-grey';
      }
      if (jobId) {
        return (
          <NavLink to={'job/' + jobId}>
            <span className={`stage-badge ${stageClass}`}>{stage}</span>
          </NavLink>
        );
      }
      return <span className={`stage-badge ${stageClass}`}>{stage}</span>;
    }
    return <></>;
  }

  nextAppointmentBodyTemplate(order: PaOrder) {
    const {appointmentsMap, loadingAppointments} = this.state;
    if (loadingAppointments) {
      return <Skeleton />;
    }
    const activeJob = order.jobs?.find(job => !inactiveJobStages.includes(job.stage));
    const appointment = appointmentsMap.get(activeJob?.id ?? '');
    if (appointment) {
      return (
        <JobAppointmentReference key={appointment.id} identifier={`${appointment.id}`} appointment={appointment} />
      );
    }
    return <></>;
  }

  handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputSwitchChangeParams
  ) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, 1000);
  };

  availableStageOptions() {
    return this.state.availableStages
      ? this.state.availableStages.map(o => {
          const option: DropdownOption = {label: o, value: o};
          return option;
        })
      : [];
  }

  availableOwnerOptions() {
    return this.state.availableOwners
      ? this.state.availableOwners.map(owner => {
          const option: DropdownOption = {
            label: owner.label ?? 'err',
            value: owner.user_id!,
          };
          return option;
        })
      : [];
  }

  showAddEditDialog() {
    const ownerId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    const newOrder: PaOrder = {
      stage: 'Estimate',
      type: 'Standard',
      priority: 1,
      owner: ownerId,
      items: [],
      freight_options: {
        freight_type: 'made2freight',
      },
      reference: '',
      shipping_address: {} as LocationReference,
    };
    this.setState({newOrder: newOrder, showAddEditDialog: true});
  }

  hideAddEditDialog() {
    this.setState({newOrder: undefined, showAddEditDialog: false});
  }

  onShowRequestJobDialog() {
    this.setState({showRequestJobDialog: true});
  }

  onHideRequestJobDialog() {
    this.setState({showRequestJobDialog: false});
  }

  initTableMenu(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    if (this.props.mode === 'Estimate') {
      menuItems.push({
        label: 'Add New',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAddEditDialog();
        },
      });
    }

    menuItems.push(...this.initMenuItems());
    if (localStorage.getItem(localStorageAttributes.currentRole) === 'admin' && this.props.mode === 'Estimate') {
      menuItems.push({
        label: `${this.state.showDeletedEstimates ? 'Hide Deleted' : 'Show Deleted'}`,
        faIcon: this.state.showDeletedEstimates ? faToggleOn : faToggleOff,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: async () => {
          this.toggleShowDeleted();
        },
      });
    }
    return menuItems;
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    const {selectedItems, company} = this.state;

    if (selectedItems.length > 0) {
      const estimatesOnly = selectedItems.every(order => order.stage === 'Estimate' || order.stage === '01 Estimate');

      menuItems.push({
        label: 'Print',
        faIcon: faPrint,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.printSelectedOrders();
        },
      });

      if (estimatesOnly) {
        if (selectedItems.length === 1) {
          menuItems.push({
            label: 'Submit',
            faIcon: ['far', 'check'],
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.onSubmit(selectedItems[0]);
            },
          });
          if (selectedItems[0].stage === 'Estimate' && company?.fitting_types && selectedItems[0].fitting_options) {
            menuItems.push({
              label: 'Request Fitting',
              faIcon: faTape,
              template: (item: AppMenuItem, options: MenuItemOptions) => {
                return <AppMenuItemTemplate item={item} options={options} />;
              },
              command: () => {
                this.onShowRequestJobDialog();
              },
            });
          }
        }
      }

      if (this.props.mode === 'Estimate') {
        const notDeletedSelected = selectedItems.filter(order => !order.deleted);
        const notDeletedCount = notDeletedSelected.length;
        if (notDeletedCount === this.state.selectedItems.length) {
          menuItems.push({
            label: 'Delete',
            faIcon: faTrash,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.deleteOrders();
            },
          });
        }
        if (notDeletedCount === 0) {
          menuItems.push({
            label: 'Undelete',
            faIcon: faTrashUndo,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              this.unDeleteOrders();
            },
          });
        }
      }
      if (selectedItems.length === 1 && selectedItems.every(order => order.stage === 'Drawing(s) Awaiting Approval')) {
        menuItems.push({
          faIcon: faFolderOpen,
          label: 'Approve drawing(s)',
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.showDrawingDialog(selectedItems[0]);
          },
        });
      }
    }

    return menuItems;
  }

  async onSubmit(order: PaOrder) {
    this.setState({showSubmitOrderDialog: true, editOrder: order as PaOrder});
  }

  onHideSubmitConfirmDialog() {
    this.setState({showSubmitOrderDialog: false});
  }

  async deleteOrders() {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!companyId) {
      this.toastService?.showError(this.toast, 'Sorry, something went wrong. Please refresh and try again.');
      return;
    } else {
      this.setState({updatingOrders: true});
    }
    this.state.selectedItems.map(order => {
      this.ordersService
        ?.deleteOrder(companyId, order.id!)
        .then(() => {
          this.toastService?.showSuccess(this.toast, `Estimate ${order.id} ${order.reference} successfully deleted.`);
          this.loadData();
        })
        .catch(error => {
          console.error(error);
          this.toastService?.showError(this.toast, `Oh no, estimate ${order.id} ${order.reference} failed to delete.`);
        })
        .finally(() => this.setState({updatingOrders: false}));
    });
  }

  async unDeleteOrders() {
    const companyId = localStorage.getItem(localStorageAttributes.currentCompanyId);
    if (!companyId) {
      this.toastService?.showError(this.toast, 'Sorry, something went wrong. Please refresh and try again.');
      return;
    } else {
      this.setState({updatingOrders: true});
    }
    this.state.selectedItems.map(order => {
      const orderPatch: OrderPatch = {
        deleted: false,
      };
      this.ordersService
        ?.updateOrder(companyId, order.id!, orderPatch)
        .then(() => {
          this.toastService?.showSuccess(
            this.toast,
            `Estimate ${order.id} ${order.reference} successfully un-deleted.`
          );
          this.loadData();
        })
        .catch(error => {
          console.error(error);
          this.toastService?.showError(
            this.toast,
            `Oh no, estimate ${order.id} ${order.reference} failed to un-delete.`
          );
        })
        .finally(() => this.setState({updatingOrders: false}));
    });
  }

  toggleShowDeleted() {
    this.setState(
      {
        showDeletedEstimates: !this.state.showDeletedEstimates,
      },
      () => {
        MessageService.sendMessage(messages.initTableMenuItems);
        this.loadData();
      }
    );
  }

  printSelectedOrders() {
    if (!this.state.selectedItems.length) {
      return;
    }

    this.setState({updatingOrders: true});

    const revisionIds: number[] = [];
    for (const selectedOrder of this.state.selectedItems) {
      if (selectedOrder.revision_id && !revisionIds.includes(selectedOrder.revision_id)) {
        revisionIds.push(selectedOrder.revision_id);
      }
    }
    const requests = [];
    for (const revisionId of revisionIds) {
      requests.push(
        this.productsService?.getProductsDefinitions(revisionId).then((data: ApiListResponse) => {
          this.state.productDefinitions.set(revisionId, data.records as PaProductDefinition[]);
        })
      );
    }
    const ordersParams: QueryParameter = {
      filters: [
        JSON.stringify({
          field: 'id',
          condition: 'in',
          value: this.state.selectedItems.map(selectedOrder => selectedOrder.id),
        }),
      ],
      aggregate: ['items'],
    };
    this.ordersService?.getOrders(ordersParams).then(data => {
      this.setState({ordersToPrint: data.records as PaOrder[]});
    });
    requests.push(ordersParams);
    Promise.all(requests)
      .then(() => {
        this.setState({printOrders: true}, () => MessageService.sendMessage(messages.printOrders));
      })
      .catch(e => {
        this.toastService?.showError(this.toast, 'Loading product definitions failed, please try again.');
        console.log(e);
      })
      .finally(() => this.setState({updatingOrders: false}));
  }

  onPrintDialogOpen() {
    this.setState({printOrders: false});
  }

  stageFilter() {
    const options = jobStages.filter(stage => !inactiveJobStages.includes(stage));
    return (
      <MultiSelect
        selectedItemTemplate={this.stageTemplate}
        itemTemplate={this.stageTemplate}
        value={this.state.filters.job_stage}
        options={options}
        name="job_stage"
        className="form-filter stage-filter"
        onChange={this.onFilterChange}
        showClear
      />
    );
  }

  render() {
    const {hiddenColumns, showDrawingsDialog, loadingOrders, updatingOrders, loadingCompany, editOrder} = this.state;
    const codeFilter = (
      <InputText
        name="order_code"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span
            className={`p-mr-1 stage-badge stage-badge-filter stage-${value
              .toLowerCase()
              .replaceAll(' ', '-')
              .replaceAll(/[()]/g, '')}`}
          >
            {value}
          </span>
        );
      }

      return <></>;
    };

    const itemTemplate = (option: DropdownOption) => {
      return (
        <span
          className={`stage-badge stage-${(option.value as string)
            .toLowerCase()
            .replaceAll(' ', '-')
            .replaceAll(/[()]/g, '')}`}
        >
          {option.value}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        value={this.state.filters.stage}
        options={this.availableStageOptions()}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const selectedOwnerTemplate = (value: string) => {
      if (value) {
        const selectedRef = this.state.availableOwners.find(owner => owner.user_id! === value);
        if (selectedRef) {
          return <span>{selectedRef.label}</span>;
        }
      }

      return <></>;
    };

    const ownerOptionTemplate = (option: DropdownOption) => {
      const optionRef = this.state.availableOwners.find(owner => owner.user_id! === option.value);
      if (optionRef) {
        return <span>{optionRef.label}</span>;
      }

      return <></>;
    };

    const ownerFilter = (
      <MultiSelect
        selectedItemTemplate={selectedOwnerTemplate}
        itemTemplate={ownerOptionTemplate}
        value={this.state.filters.owner_ids}
        options={this.availableOwnerOptions()}
        name="owner"
        className="form-filter"
        onChange={e => {
          this.onFilterOwnerChange(e);
        }}
        showClear
      />
    );

    const approvedAtFilter = (
      <DateColumnFilter
        name="approved_at"
        value={this.state.filters.approved_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const createdAtFilter = (
      <DateColumnFilter
        name="created_at"
        value={this.state.filters.created_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const submittedAtFilter = (
      <DateColumnFilter
        name="submitted_at"
        value={this.state.filters.submitted_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const etaFilter = (
      <DateColumnFilter name="eta" value={this.state.filters.eta} onChange={e => this.onFilterChange(e)} />
    );

    const deliveredFilter = (
      <DateColumnFilter
        name="delivered_at"
        value={this.state.filters.delivered_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const ecdFilter = (
      <DateColumnFilter name="ecd" value={this.state.filters.ecd} onChange={e => this.onFilterChange(e)} />
    );

    const summaryFilter = (
      <InputText
        name="summary"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const containerFilter = (
      <InputText
        name="container_id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="order_list_page_container" className={'page-container'}>
        <TwoDataTable
          pageSizeIdentifier={'order_list_page_container'}
          sizeIdentifiers={[]}
          selectedItems={this.state.selectedItems}
          selectionMode="multiple"
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as PaOrder[])}
          loading={loadingOrders || loadingCompany || updatingOrders}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          initMenuItems={() => this.initTableMenu()}
          isMenuDynamic={true}
        >
          <Column
            header="Order Code"
            field="order_code"
            body={this.codeBodyTemplate}
            filter
            filterElement={codeFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Reference"
            field="reference"
            filter
            filterElement={referenceFilter}
            sortable
            style={{width: '360px'}}
            showFilterMenu={false}
          />
          <Column
            header="Summary"
            field="summary"
            filter
            filterElement={summaryFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field="stage"
            body={this.stageBodyTemplate}
            filter
            filterElement={stageFilter}
            sortable
            style={{width: '160px'}}
            showFilterMenu={false}
          />
          <Column
            header="Owner"
            field="owner_contact_ref"
            body={this.ownerBodyTemplate}
            filter
            filterElement={ownerFilter}
            sortable
            style={{width: '160px'}}
            showFilterMenu={false}
          />
          <Column
            header="Created"
            field="created_at"
            body={this.createdAtBodyTemplate}
            filter
            filterElement={createdAtFilter}
            sortable
            hidden={hiddenColumns.has('created_at')}
            style={{width: '90px'}}
            showFilterMenu={false}
          />
          <Column
            header="Submitted"
            field="submitted_at"
            body={this.submittedAtBodyTemplate}
            filter
            filterElement={submittedAtFilter}
            sortable
            hidden={hiddenColumns.has('submitted_at')}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Approved"
            field="approved_at"
            body={this.approvedAtBodyTemplate}
            filter
            filterElement={approvedAtFilter}
            sortable
            hidden={hiddenColumns.has('approved_at')}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="ECD"
            field="ecd"
            body={this.ecdBodyTemplate}
            filter
            filterElement={ecdFilter}
            sortable
            hidden={hiddenColumns.has('ecd')}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Container"
            field="container_id"
            filter
            filterElement={containerFilter}
            sortable
            hidden={hiddenColumns.has('container_id')}
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="ETA"
            field="eta"
            body={this.etaBodyTemplate}
            filter
            filterElement={etaFilter}
            sortable
            hidden={hiddenColumns.has('eta')}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Delivered"
            field="delivered_at"
            body={this.deliveredBodyTemplate}
            filter
            filterElement={deliveredFilter}
            sortable
            hidden={hiddenColumns.has('delivered_at')}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          {this.state.showDeletedEstimates && (
            <Column header="Deleted" field="deleted" body={this.deleteBodyTemplate} sortable />
          )}
          <Column
            header="Job Stage"
            field="job_stage"
            body={this.jobStageBodyTemplate}
            hidden={hiddenColumns.has('job_stage')}
            style={{width: '80px'}}
            filter
            filterElement={this.stageFilter}
            showFilterMenu={false}
          />
          <Column
            header="Next App"
            field="job_appointment"
            body={this.nextAppointmentBodyTemplate}
            hidden={hiddenColumns.has('job_appointment')}
            style={{width: '80px'}}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddEditOrderDialog
          toast={this.toast}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddEditDialog}
          newOrder={this.state.newOrder}
        />
        {editOrder && (
          <SubmitOrderConfirmDialog
            showDialog={this.state.showSubmitOrderDialog}
            onHide={this.onHideSubmitConfirmDialog}
            toast={this.toast}
            orderId={editOrder.id!}
          />
        )}
        <RequestJobDialog
          showDialog={this.state.showRequestJobDialog}
          onHide={this.onHideRequestJobDialog}
          toast={this.toast}
          estimate={this.state.selectedItems[0]}
        />
        {this.state.printOrders && (
          <OrdersPrinterComponent
            orders={this.state.ordersToPrint}
            productDefinitionRevisions={this.state.productDefinitions}
            triggerMessage={messages.printOrders}
            onPrintDialogOpen={this.onPrintDialogOpen}
          />
        )}
        {showDrawingsDialog && editOrder && editOrder.stage === 'Drawing(s) Awaiting Approval' && (
          <DrawingsDialog
            mode={'drawing_review'}
            onSave={this.onDrawingsSave}
            order={editOrder}
            showDialog={showDrawingsDialog}
            onHide={this.onHideDrawingDialog}
            loading={loadingOrders}
          />
        )}
      </div>
    );
  }
}

export default OrderListComponent;
