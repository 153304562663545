import React from 'react';
import {AppContext, MessageService, OrderItemsComponent, ToastService, TwoDialog} from 'two-app-ui';
import {messages} from '../../../config/messages';
import {
  Draft,
  Field,
  OrderItem,
  OrderStage,
  DraftPatch,
  PaProductDefinition,
  PaOrder,
  LocationReference,
  Location,
  PaContact,
  CompanyFreightOption,
  FreightOptions,
  PaSharedLocation,
  Made2FreightOptions,
  PickUpFreightOptions,
  StorageFreightOptions,
  ThirdPartyFreightOptions,
  SharedLocationAggregate,
  QueryParameter,
  Company,
} from 'two-core';
import DraftsService from '../../../services/DraftsService';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import ProductsService from '../../../services/ProductsService';
import {InputText} from 'primereact/inputtext';
import OrdersService from '../../../services/OrdersService';
import {ShippingAddressDialog} from '../../ShippingAddressDialog/ShippingAddressDialog';
import {localStorageAttributes} from '../../../config/localStorageAttributes';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import M2OUsersService from '../../../services/M2OUsersService';
import ContactsService from '../../../services/ContactsService';
import {InputTextarea} from 'primereact/inputtextarea';
import {thirdPartyFreightServicesOptions} from '../../../config/values';
import {SharedLocationsService} from '../../../services/SharedLocationsService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  draft?: Draft;
  productDefinitions?: PaProductDefinition[];
}

interface State {
  loading: boolean;
  draftPatch: DraftPatch;
  contacts: PaContact[];
  selectedOwner?: PaContact;
  productDefinitions: PaProductDefinition[];
  clonedItems: OrderItem[];
  invalidItemMessagesMap?: Map<number, string[]>;
  showShippingAddressDialog: boolean;
  shippingAddressRef?: LocationReference;
  draftFreightOptions?: FreightOptions;
  companyFreightOptions: CompanyFreightOption[];
  storageSharedLocations: PaSharedLocation[];
  pickUpSharedLocations: PaSharedLocation[];
}

export class NewEditDraftDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  usersService?: M2OUsersService;
  ordersService?: OrdersService;
  draftsService?: DraftsService;
  toastService?: ToastService;
  productsService?: ProductsService;
  contactsService?: ContactsService;
  sharedLocationsService?: SharedLocationsService;

  constructor(props: Props) {
    super(props);
    this.state = {
      clonedItems: [],
      contacts: [],
      invalidItemMessagesMap: new Map<number, string[]>(),
      loading: false,
      draftPatch: {},
      productDefinitions: [],
      showShippingAddressDialog: false,
      companyFreightOptions: [],
      storageSharedLocations: [],
      pickUpSharedLocations: [],
    };

    this.loadData = this.loadData.bind(this);
    this.loadContacts = this.loadContacts.bind(this);
    this.onReferenceChange = this.onReferenceChange.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.onInputOwnerChange = this.onInputOwnerChange.bind(this);
    this.contactValueTemplate = this.contactValueTemplate.bind(this);
    this.contactOptionTemplate = this.contactOptionTemplate.bind(this);
    this.onItemsChange = this.onItemsChange.bind(this);
    this.setInvalidItemMessagesMap = this.setInvalidItemMessagesMap.bind(this);
    this.cloneItems = this.cloneItems.bind(this);
    this.validate = this.validate.bind(this);
    this.createDraft = this.createDraft.bind(this);
    this.updateDraft = this.updateDraft.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.onSaveDraftButtonClick = this.onSaveDraftButtonClick.bind(this);
    this.onSaveEstimateButtonClick = this.onSaveEstimateButtonClick.bind(this);
    this.onSaveEstimate = this.onSaveEstimate.bind(this);
    this.onValidateAllButtonClick = this.onValidateAllButtonClick.bind(this);
    this.onShippingAddressDialogHide = this.onShippingAddressDialogHide.bind(this);
    this.onShippingAddressChange = this.onShippingAddressChange.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  async componentDidMount() {
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;
    this.ordersService = this.context.ordersService;
    this.draftsService = this.context.draftsService;
    this.productsService = this.context.productsService;
    this.contactsService = this.context.contactsService;
    this.sharedLocationsService = this.context.sharedLocationsService;
  }

  async loadData() {
    //todo this need to be refactored
    const {draft} = this.props;
    const currentCompanyString = localStorage.getItem(localStorageAttributes.currentCompany) ?? '[]';
    const currentCompany = JSON.parse(currentCompanyString) as Company;
    const companyFreightOptionsText = currentCompany.freight_options as string | undefined;
    const companyFreightOptions: CompanyFreightOption[] = (companyFreightOptionsText?.split(',') as
      | CompanyFreightOption[]
      | undefined) ?? ['Made 2 Freight - Flat'];
    this.setState({
      loading: true,
    });
    let draftFreightOptions: FreightOptions | undefined;
    if (draft) {
      this.cloneItems(draft.items);
      draftFreightOptions = draft.freight_options;
    } else {
      if (companyFreightOptions.includes('Made 2 Freight - Flat')) {
        draftFreightOptions = {freight_type: 'Made 2 Freight', pricing: 'Flat'} as Made2FreightOptions;
      }
    }
    if (this.props.productDefinitions) {
      this.setState({productDefinitions: this.props.productDefinitions});
    } else {
      this.setState({loading: true});
      const revisionId = draft?.revision_id;
      this.loadProductDefinitions(revisionId)
        .catch(error => {
          console.error('Failed Loading Data:' + error);
          this.toastService?.showError(this.props.toast, 'Failed loading data, please refresh and try again.');
        })
        .finally(() => {
          this.setState({loading: false});
        });
    }
    this.loadContacts();
    this.loadSharedLocations(currentCompany.id ?? '');
    this.setState(() => ({draftFreightOptions, companyFreightOptions}));
  }

  async loadContacts() {
    if (localStorage.getItem(localStorageAttributes.currentRole) === 'admin') {
      const sortBy = JSON.stringify({
        field: 'first_name',
        direction: 'ASC',
      });

      this.contactsService
        ?.getContacts({orderBys: [sortBy]})
        .then(data => {
          const contacts = (data.records as PaContact[]) ?? [];
          this.setState({
            contacts: contacts,
            selectedOwner: contacts.find(
              contact =>
                contact.id === this.props.draft?.owner_contact_ref?.contact_id ?? this.usersService?.myContact?.id
            ),
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({contacts: []});
        });
    }
  }

  async loadSharedLocations(currentCompanyId: string) {
    try {
      const filters = [
        JSON.stringify({
          field: 'company_id',
          value: currentCompanyId,
        }),
      ];
      const aggregate: SharedLocationAggregate[] = ['location'];
      const params: QueryParameter = {
        filters: filters,
        aggregate: aggregate,
      };
      const sharedLocationsResult = await this.sharedLocationsService?.getSharedLocations(params);
      const sharedLocations = sharedLocationsResult?.records as PaSharedLocation[];
      const storageSharedLocations = sharedLocations?.filter(sl => sl.purpose === 'Storage') ?? [];
      const pickUpSharedLocations = sharedLocations?.filter(sl => sl.purpose === 'Pick Up') ?? [];
      this.setState(() => ({
        storageSharedLocations,
        pickUpSharedLocations,
        loading: false,
      }));
    } catch (e) {
      console.error('Failed loading shared locations:', e);
    }
  }

  async loadProductDefinitions(revisionId?: number) {
    return this.productsService?.getProductsDefinitions(revisionId).then(data => {
      const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
      this.setState({
        productDefinitions: productDefinitions,
      });
    });
  }

  setInvalidItemMessagesMap(newInvalidItemMessagesMap: Map<number, string[]>) {
    this.setState({
      invalidItemMessagesMap: newInvalidItemMessagesMap,
    });
  }

  cloneItems(items: OrderItem[]) {
    const clonedItems = (structuredClone(items) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        //we must convert field to class object
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              //we must convert subfield to class object
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    this.setState({
      clonedItems: clonedItems,
    });
  }

  onReferenceChange(e: React.ChangeEvent<HTMLInputElement>) {
    const draftPatch = this.state.draftPatch;
    const value = e.target.value;
    const updatedDraftPatch = {
      ...draftPatch,
      reference: value,
    };
    this.setState({draftPatch: updatedDraftPatch});
  }

  onNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const draftPatch = this.state.draftPatch;
    const value = e.target.value;
    const updatedDraftPatch = {
      ...draftPatch,
      note: value,
    };
    this.setState({draftPatch: updatedDraftPatch});
  }

  onInputOwnerChange = (e: DropdownChangeParams) => {
    this.setState({selectedOwner: e.value});
  };

  contactValueTemplate(contact: PaContact) {
    if (contact) {
      return <div>{`${contact.first_name} ${contact.last_name} [${contact.role}]`}</div>;
    } else {
      return 'empty';
    }
  }

  contactOptionTemplate(contact: PaContact) {
    return <div>{`${contact.first_name} ${contact.last_name} [${contact.role}]`}</div>;
  }

  onItemsChange(newItems: OrderItem[]) {
    const draftPatch = this.state.draftPatch;

    const updatedDraftPatch = {
      ...draftPatch,
      items: newItems,
    };
    this.setState({
      draftPatch: updatedDraftPatch,
      clonedItems: newItems,
    });
  }

  onSaveDraftButtonClick() {
    const {draft} = this.props;
    if (this.validate(false, 'Draft')) {
      if (draft?.id) {
        this.updateDraft();
      } else {
        this.createDraft();
      }
    }
  }

  onSaveEstimate() {
    this.createOrder();
  }

  onSaveEstimateButtonClick() {
    if (this.validate(false)) {
      this.setState({showShippingAddressDialog: true});
    }
  }

  onValidateAllButtonClick() {
    this.validate(true);
  }

  async createDraft() {
    this.setState({loading: true});

    const ownerId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    const newDraft: Draft = {
      revision_id: this.state.productDefinitions[0].revision_id,
      size: 0,
      owner: ownerId,
      deleted: false,
      ...this.state.draftPatch,
    } as Draft;
    if (!newDraft.items) {
      newDraft.items = [];
    }
    if (this.state.selectedOwner && this.state.selectedOwner.id) {
      newDraft.owner_contact_ref = {
        contact_id: this.state.selectedOwner.id,
      };
    }
    if (this.state.draftFreightOptions) {
      newDraft.freight_options = this.state.draftFreightOptions;
    }

    return this.draftsService
      ?.createDraft(ownerId, newDraft)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, `Draft ${newDraft.reference} created successfully.`);
        this.onHide();
        MessageService.sendMessage(messages.draftCreated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, draft creation failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  async updateDraft() {
    this.setState({loading: true});
    const draft = this.props.draft!;
    const draftPatch = {...this.state.draftPatch};
    const ownerId = localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    if (
      this.state.selectedOwner &&
      this.state.selectedOwner.id &&
      this.state.selectedOwner.id !== draft.owner_contact_ref?.contact_id
    ) {
      draftPatch.owner_contact_ref = {
        contact_id: this.state.selectedOwner.id,
      };
    }
    if (!draftPatch.items) {
      draftPatch.items = [];
    }
    if (this.state.draftFreightOptions) {
      draftPatch.freight_options = this.state.draftFreightOptions;
    }

    return this.draftsService
      ?.updateDraft(ownerId, draft.id!, draftPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, `Draft ${draft.id} ${draft.reference} updated successfully.`);
        MessageService.sendMessage(messages.draftUpdated);
        this.onHide();
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, edit failed, please try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  async createOrder() {
    const {draftPatch, productDefinitions, selectedOwner, shippingAddressRef} = this.state;
    const {draft} = this.props;
    this.setState({loading: true});

    const ownerId = draft?.owner ?? localStorage.getItem(localStorageAttributes.currentCompanyId) ?? ' ';
    const newOrder: PaOrder = {
      stage: 'Estimate',
      type: 'Standard',
      priority: 1,
      owner: ownerId,
      items: draftPatch.items ?? draft!.items,
      freight_options: {
        freight_type: 'made2freight',
      },
      reference: draftPatch.reference ?? draft!.reference,
      shipping_address: shippingAddressRef as LocationReference,
      revision_id: productDefinitions[0].revision_id,
    };
    if (selectedOwner && selectedOwner.id) {
      newOrder.owner_contact_ref = {
        contact_id: selectedOwner.id,
      };
    }
    if (draft?.id) {
      newOrder.draft_id = draft.id;
    }
    if (this.state.draftFreightOptions) {
      newOrder.freight_options = this.state.draftFreightOptions;
    }

    return this.ordersService
      ?.createOrder(ownerId, newOrder)
      .then(async (order: PaOrder) => {
        this.toastService?.showSuccess(this.props.toast, `Order ${order.id} ${order.reference} created successfully.`);
        MessageService.sendMessage(messages.orderCreated);
        this.onHide();
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, order creation failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  validate(showSuccessfulDialog: boolean, stage?: OrderStage): boolean {
    const {draftPatch} = this.state;
    const {draft} = this.props;
    const {invalidItemMessagesMap} = this.state;

    const errors: string[] = [];
    const itemsErrors = [];
    const reference = draftPatch?.reference ?? draft?.reference ?? '';
    if (!reference?.length) {
      errors.push('Reference field is empty.');
    }
    if (localStorage.getItem(localStorageAttributes.currentRole) === 'admin' && !this.state.selectedOwner) {
      errors.push('Owner not chosen.');
    }
    if (stage !== 'Draft') {
      const items = draftPatch?.items ?? draft?.items ?? [];
      if (!items?.length) {
        errors.push('Order must have at least one item.');
      }
      if (invalidItemMessagesMap?.size) {
        for (const [itemIndex, messages] of Array.from(invalidItemMessagesMap.entries())) {
          itemsErrors.push(
            <div>
              Item {itemIndex} is invalid:
              {messages.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </div>
          );
        }
      }
    }

    if (errors.length || itemsErrors.length) {
      const errorText = (
        <>
          {!!errors.length && (
            <div>
              Form is invalid:
              {errors.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </div>
          )}
          {!!itemsErrors.length && itemsErrors}
        </>
      );
      this.toastService?.showError(this.props.toast, errorText);
      MessageService.sendMessage(messages.orderCanNotBeSaved);
      return false;
    }
    if (showSuccessfulDialog) {
      this.toastService?.showSuccess(this.props.toast, 'All items valid.');
      MessageService.sendMessage(messages.orderCanNotBeSaved);
    }
    return true;
  }

  onShippingAddressDialogHide() {
    this.setState({showShippingAddressDialog: false});
  }

  onShippingAddressChange(location: Location) {
    const newAddressRef: LocationReference = {
      address:
        location.address.street +
        ', ' +
        location.address.suburb +
        ', ' +
        location.address.state +
        ', ' +
        location.address.country,
      id: location.id!,
    };
    this.setState({shippingAddressRef: newAddressRef});
  }

  onHide() {
    this.setState({
      clonedItems: [],
      draftPatch: {},
      shippingAddressRef: undefined,
      showShippingAddressDialog: false,
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  onFreightOptionsChange(
    orderFreightOptionsPatch: Partial<
      FreightOptions | PickUpFreightOptions | StorageFreightOptions | Made2FreightOptions | ThirdPartyFreightOptions
    >
  ) {
    const {draftFreightOptions} = this.state;
    let updatedDraftFreightOptions;
    if (orderFreightOptionsPatch.freight_type) {
      updatedDraftFreightOptions = {
        freight_type: orderFreightOptionsPatch.freight_type,
        delivery_note: draftFreightOptions?.delivery_note,
      };
    } else {
      updatedDraftFreightOptions = {
        ...draftFreightOptions,
        ...orderFreightOptionsPatch,
      } as FreightOptions;
    }
    this.setState({draftFreightOptions: updatedDraftFreightOptions});
  }

  renderFreightOptionsRow(
    companyFreightOptions: CompanyFreightOption[],
    draftFreightOptions?: FreightOptions,
    pickUpSharedLocations?: PaSharedLocation[],
    storageSharedLocations?: PaSharedLocation[]
  ) {
    const deliveryOptions: string[] = [];
    const pricingOptions: string[] = [];
    for (const companyFreightOption of companyFreightOptions) {
      if (companyFreightOption.includes('Made 2 Freight - Flat')) {
        if (!deliveryOptions.includes('Made 2 Freight')) {
          deliveryOptions.push('Made 2 Freight');
        }
        pricingOptions.push('Flat');
      } else if (companyFreightOption.includes('Made 2 Freight - Per Drop')) {
        if (!deliveryOptions.includes('Made 2 Freight')) {
          deliveryOptions.push('Made 2 Freight');
        }
        pricingOptions.push('Per Drop');
      } else {
        deliveryOptions.push(companyFreightOption);
      }
    }
    return (
      <div className="p-field p-grid">
        <label htmlFor="fitting" className="p-col-2">
          delivery
        </label>
        <div className="p-col-4">
          <Dropdown
            value={draftFreightOptions?.freight_type}
            options={deliveryOptions}
            onChange={e => this.onFreightOptionsChange({freight_type: e.value})}
          />
        </div>
        {draftFreightOptions?.freight_type === 'Made 2 Freight' && (
          <>
            <label className="p-col-2">pricing</label>
            <div className="p-col-4">
              <Dropdown
                value={(draftFreightOptions as Made2FreightOptions)?.pricing}
                options={pricingOptions}
                onChange={e => this.onFreightOptionsChange({pricing: e.value})}
              />
            </div>
          </>
        )}
        {draftFreightOptions?.freight_type === 'Pick Up' && (
          <>
            <label className="p-col-2">from</label>
            <div className="p-col-4">
              <Dropdown
                value={(draftFreightOptions as PickUpFreightOptions)?.location_id}
                options={pickUpSharedLocations}
                onChange={e => this.onFreightOptionsChange({location_id: e.value})}
                optionLabel="location.name"
                optionValue="location_id"
                dataKey="location_id"
              />
            </div>
          </>
        )}
        {draftFreightOptions?.freight_type === 'Storage' && (
          <>
            <label className="p-col-2">to</label>
            <div className="p-col-4">
              <Dropdown
                value={(draftFreightOptions as StorageFreightOptions)?.location_id}
                options={storageSharedLocations}
                onChange={e => this.onFreightOptionsChange({location_id: e.value})}
                optionLabel="location.name"
                optionValue="location_id"
                dataKey="location_id"
              />
            </div>
          </>
        )}
        {draftFreightOptions?.freight_type === '3rd Party' && (
          <>
            <label className="p-col-2">service</label>
            <div className="p-col-4">
              <Dropdown
                value={(draftFreightOptions as ThirdPartyFreightOptions)?.service_name}
                options={thirdPartyFreightServicesOptions}
                onChange={e => this.onFreightOptionsChange({service_name: e.value})}
              />
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    const {
      loading,
      draftPatch,
      contacts,
      selectedOwner,
      showShippingAddressDialog,
      companyFreightOptions,
      draftFreightOptions,
      pickUpSharedLocations,
      storageSharedLocations,
    } = this.state;
    const {draft} = this.props;

    const freightOptionsRow = this.renderFreightOptionsRow(
      companyFreightOptions,
      draftFreightOptions,
      pickUpSharedLocations,
      storageSharedLocations
    );
    let dialogBody;
    let footer = <></>;
    if (loading) {
      dialogBody = (
        <div className="overlay">
          <ProgressSpinner className="overlay-spinner" />
        </div>
      );
    } else {
      footer = (
        <div className={'p-d-flex p-justify-end'}>
          <Button label="Cancel" className="p-button-text" onClick={this.onHide} autoFocus />
          <Button className="p-ml-2" label="Save Draft" onClick={this.onSaveDraftButtonClick} />
          <Button className="p-ml-2" label="Validate All" onClick={this.onValidateAllButtonClick} />
          <Button className="p-ml-2" label="Send to FF2" onClick={this.onSaveEstimateButtonClick} />
        </div>
      );
      dialogBody = (
        <div id="new_edit_draft_dialog" className="p-fluid w-100 p-mx-2">
          <div className="p-field p-grid">
            <label htmlFor="reference" className="p-col-2">
              reference
            </label>
            <div className="p-col-10">
              <InputText value={draftPatch?.reference ?? draft?.reference ?? ''} onChange={this.onReferenceChange} />
            </div>
          </div>
          {!!contacts.length && (
            <div className="p-field p-grid">
              <label htmlFor="reference" className="p-col-2">
                owner
              </label>
              <div className="p-col-10">
                <Dropdown
                  id="owner_selector"
                  value={selectedOwner}
                  options={contacts}
                  onChange={this.onInputOwnerChange}
                  dataKey="id"
                  optionLabel="id"
                  valueTemplate={this.contactValueTemplate}
                  itemTemplate={this.contactValueTemplate}
                />
              </div>
            </div>
          )}
          {freightOptionsRow}
          <div className="p-field p-grid">
            <label htmlFor="reference" className="p-col-2">
              note
            </label>
            <div className="p-col-10">
              <InputTextarea autoResize value={draftPatch?.note ?? draft?.note ?? ''} onChange={this.onNoteChange} />
            </div>
          </div>
          {!!this.state.productDefinitions.length && (
            <OrderItemsComponent
              mode={'advanced_edit'}
              items={this.state.clonedItems}
              productDefinitions={this.state.productDefinitions}
              onItemsChanged={this.onItemsChange}
              setInvalidItemMessagesMap={this.setInvalidItemMessagesMap}
            />
          )}
          <ShippingAddressDialog
            showDialog={showShippingAddressDialog}
            onHide={this.onShippingAddressDialogHide}
            onSaveEstimateButtonClick={this.onSaveEstimate}
            onShippingAddressChange={this.onShippingAddressChange}
            toast={this.props.toast}
          />
        </div>
      );
    }

    return (
      <TwoDialog
        header={draft ? 'Edit Draft - ' + draft.id : 'Add Draft'}
        showDialog={this.props.showDialog}
        onShow={this.onShow}
        onHide={this.onHide}
        loading={this.state.loading}
        footer={footer}
        style={{width: '70vw'}}
        breakpoints={{'1920px': '90vw'}}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
